<template>
  <StaticCard
    :border-color="themeColor"
    :card-shadow="false"
    :card-title="title"
    :card-subtitle="subtitle"
    class="shadow-md sm:text-base content-card"
  >
    <template #card-image>
      <div v-if="image && image.src" class="aspect-w-16 aspect-h-9 relative">
        <LazyImg
          src="/images/cpn.png"
          :data-src="imgSource(image.src)"
          class="object-cover w-full h-full"
          :alt="image.alt"
        />
      </div>
    </template>

    <div v-html="$md.render(content)" />

    <template #cardFooter>
      <A11yButton
        v-if="cta && cta.text"
        variant="primary"
        class="mt-auto ml-auto gap-2 cursor-pointer"
        :href="cta.href"
        :component="cta.component"
        :linktype="cta.linktype"
      >
        <span> {{ cta.text }} </span>
        <Icon name="IconArrowRight" class="text-2xl" />
      </A11yButton>
    </template>
  </StaticCard>
</template>

<script>
import useHelpers from '~/composables/useHelpers'

export default {
  name: 'ContentCard',
  props: {
    image: {
      type: Object,
      required: false,
      default: () => {}
    },
    themeColor: {
      type: String,
      default: 'azure'
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    content: {
      type: String,
      required: false,
      default: ''
    },
    cta: { type: Object, required: false, default: () => {} },
    link: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  setup() {
    const { getImgSrc } = useHelpers()
    return { getImgSrc }
  },
  methods: {
    imgSource(imgSrc) {
      return this.getImgSrc(imgSrc)
    }
  }
}
</script>
